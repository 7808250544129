import Archive from "@whitespace/gatsby-theme-wordpress-basic/src/components/Archive";
import ClientOnly from "@whitespace/gatsby-theme-wordpress-basic/src/components/ClientOnly";
import {
  PageContextProvider,
  useContentType,
} from "@whitespace/gatsby-theme-wordpress-basic/src/hooks";
import React from "react";

export default function EventsEnPage() {
  const contentType = useContentType("event");
  contentType.uri = "/en/events";
  const pageContext = { contentType };
  return (
    <PageContextProvider value={pageContext}>
      <ClientOnly fallback={null}>
        <Archive />
      </ClientOnly>
    </PageContextProvider>
  );
}
